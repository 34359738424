import React, { useRef, useState } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { em } from 'polished';
import { Location } from '../../../types';
import RoutesContainer from '../containers/RoutesContainer';
import { FlightPath, FlightPoint } from '../../../types/generated';

// @ts-ignore
const AsyncMap = loadable(() => import(/* webpackPrefetch: true */ '../containers/MapContainer'), { ssr: false });

const RouteMasterStyled = styled.div<any>`
  display: grid;
  grid-gap: ${em(20)};
  grid-template-columns: 290px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
        "aside map";

  .routes {
    grid-area: aside;
  }

  .map {
    grid-area: map;
    width: 100%;
    height: 560px;
    z-index: 0;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "aside"
      "map";
  }
`;

interface Props {
  points: FlightPoint[];
  pointsMap: Map<Location, FlightPoint>;
}

export default function RouteMaster(props: Props) {
  const { pointsMap } = props;
  const [routeIndex, setRouteIndex] = useState(0);
  const mapRef = useRef<any>();

  function onRouteClick(index) {
    setRouteIndex(index);
    mapRef.current.centreMap();
  }

  function onPathClick(path: FlightPath) {
    mapRef.current.focusPath(path);
  }

  function onPointClick(location: Location) {
    const point: FlightPoint = pointsMap.get(location);
    mapRef.current.focusPoint(point);
  }

  return (
    <RouteMasterStyled>
      <RoutesContainer
        className="routes"
        onPathClick={onPathClick}
        onPointClick={onPointClick}
        onRouteClick={onRouteClick}
      />
      <AsyncMap
        className="map"
        routeIndex={routeIndex}
        innerRef={mapRef}
      />
    </RouteMasterStyled>
  );
}
