import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { getTransportIcon } from '../utils/utils';
import { capitalize } from '../../../shared/utils';
import { FlightPath } from '../../../types/generated';

const StyledTransportIcon = styled<any>(FontAwesomeIcon)`
  padding: 6px 12px;
  width: 1.2em !important;
  max-width: 1.2em !important;
  font-size: 1.2em;

  &:hover {
    color: var(--secondaryLight-color)
  }
`;

interface Props {
  className?: string;
  path: FlightPath;
  onClick?: () => void;
}

export default function TransportIcon(props: Props) {
  const { className, path, onClick } = props;
  return (
    <div className={className}>
      <ReactTooltip
        place="top"
        border
        getContent={(transport) => <div>{transport}</div>}
        effect="solid"
      />
      <StyledTransportIcon
        onClick={onClick}
        data-tip={capitalize(path.transport)}
        icon={getTransportIcon(path.transport)}
      />
    </div>
  );
}
