import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import TransportIcon from './TransportIcon';
import TransportDuration from './TransportDuration';
import { FlightPath } from '../../../types/generated';

const StyledRouteSummary = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: ${em(8)};
  background-color: var(--secondaryDarkAlpha-color);
  padding: ${em(8)} ${em(6)};
  align-items: center;

  &:hover {
    outline: ${em(1)} solid var(--secondaryLightAlpha-color);
    cursor: pointer;
  }

  .paths {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .icon-wrapper {
    position: relative;

    &:not(:last-child)::after {
      color: white;
      content: "●";
      position: absolute;
      display: block;
      font-size: 1.1em;
      top: 50%;
      transform: translateY(-50%);
      right: -5px;
    }
  }

  .duration {
    padding: ${em(0)} ${em(10)};
  }
`;

interface Props {
  paths: FlightPath[];
}

export default function RouteSummary(props: Props) {
  const { paths } = props;

  const duration = paths.reduce((prev, curr) => prev + curr.duration, 0);

  return (
    <StyledRouteSummary>
      <div className="paths">
        {
          [...paths].map((path, index) => (
            <TransportIcon
              className="icon-wrapper"
              path={path}
              key={`path${index * 2}`}
            />
          ))
        }
      </div>
      <TransportDuration className="duration" duration={duration} />
    </StyledRouteSummary>
  );
}
