import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import { graphql, useStaticQuery } from 'gatsby';
import RouteMasterContainer from '../client/flightmaster/containers/RouteMasterContainer';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';
import Top from '../client/names/components/Top';
import FormsContainer from '../client/flightmaster/containers/FormsContainer';
import RelatedTools from '../client/common/components/RelatedTools';
import FlightMasterProvider from '../client/flightmaster/context/flightmaster-provider';
import Notes from '../client/flightmaster/components/Notes';
import Wowhead from '../client/common/components/Wowhead';

const Page = styled.div`
  display: grid;
  grid-template-rows: max-content;
  grid-gap: ${em(20)};
`;

const FlightMaster = ({ location }) => {
  const data = useStaticQuery(graphql`
      query NotesQuery {
          markdownRemark(fileAbsolutePath: { regex: "/flight-options.md/" }) {
              htmlAst
          }
      }
  `);
  const { markdownRemark: { htmlAst } } = data;

  return (
    <FlightMasterProvider>
      <Layout mainClass="page-padding-height">
        <SEO
          title="Flight Master | WoW Classic"
          description="Ultimate tool for navigating vanilla Azeroth in WoW Classic with an interactive map of routes, flight paths and travel points."
          location={location}
        />
        <Wowhead />
        <Page>
          <Top
            className="page-container page-padding-width"
            title="Flight Master"
            description={`
            Find the fastest routes when travelling vanilla Azeroth in WoW classic. Including routes via flight paths, ships, zeppelins and tram
            for horde, alliance and neutral factions. Want to find the fastest way to Booty Bay or Light's Hope Chapel? Look no further!
          `}
          />
          <div className="page-container page-padding-width content">
            <FormsContainer />
            <RouteMasterContainer />
          </div>
        </Page>
        <div className="page-container page-padding-width">
          <br />
          <hr />
          <Notes htmlAst={htmlAst} />
          <br />
          <hr />
          <RelatedTools />
        </div>
      </Layout>
    </FlightMasterProvider>
  )
};

export default FlightMaster;
