import React from 'react';
import Loader from '../../common/components/Loader';
import RouteMaster from '../components/RouteMaster';
import { fromPointsToMap } from '../../../shared/utils';
import ErrorStyled from '../../common/components/ErrorStyled';
import { useGetPointsQuery } from '../../../types/generated';

export default function RouteMasterContainer() {
  const res = useGetPointsQuery();
  const { data, loading, error } = res;

  if (error) {
    return <ErrorStyled>{error.message}</ErrorStyled>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <RouteMaster points={data.getPoints} pointsMap={fromPointsToMap(data.getPoints)} />
  );
}
