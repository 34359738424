import React, { ReactNode, useState } from 'react';
import { FlightMasterContext } from './flightmaster-context';

interface Props {
  children: ReactNode;
}

export default function FlightMasterProvider(props: Props) {
  const { children } = props;
  const [showAllPoints, setShowAllPoints] = useState(false);

  return (
    <FlightMasterContext.Provider value={{ showAllPoints, setShowAllPoints }}>
      {children}
    </FlightMasterContext.Provider>
  );
}
