import React from 'react';
import { em } from 'polished';
import Select from 'react-select';
import styled from 'styled-components';
import { Location } from '../../../types';
import { getPointColor } from '../utils/utils';
import { capitalize } from '../../../shared/utils';
import { Faction, FlightPoint, FlightType } from '../../../types/generated';

const styles = {
  control: (provided) => ({
    ...provided,
    background: 'var(--primaryLight-color)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginLeft: '56px',
  }),
  singleValue: (provided, { data, isFocused }) => ({
    ...provided,
    padding: `${em(4)} ${em(8)}`,
    background: isFocused ? getPointColor(data.point, 'dark') : getPointColor(data.point, 'light'),
  }),
  menu: (provided) => ({
    ...provided,
    background: 'var(--primaryLight-color)',
  }),
  group: (provided) => ({
    ...provided,
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: 'black',
    fontSize: '1em',
    fontWeight: 'bold',
  }),
  option: (provided, { data, isFocused }) => ({
    ...provided,
    color: 'black',
    borderBottom: `${em(1)} solid var(--primaryDarkAlpha-color)`,
    background: isFocused ? getPointColor(data.point, 'dark') : getPointColor(data.point, 'light'),
  }),
};

const StyledPointSelect = styled.div`
  .wrapper {
    position: relative;
  }

  .label {
    font-family: 'Bitter',serif;
    color: var(--primaryDark-color);
    letter-spacing: -1px;
    text-align: center;
    font-size: 1em;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 45px;
  }
`;

function sortName(a: FlightPoint, b: FlightPoint) {
  return a.location.localeCompare(b.location);
}

function flightPointToLabel(point: FlightPoint) {
  return {
    value: point.location,
    label: point.location,
    point,
  };
}

function getPoint(points: FlightPoint[], location: Location) {
  const pointFound = points.find((point) => point.location === location);
  if (pointFound) {
    return flightPointToLabel(pointFound);
  }
  return null;
}

function getPoints(points: FlightPoint[], faction: Faction | null, type: FlightType = null, clazz: 'druid' = null) {
  return points
    .filter((point) => {
      const isFaction = !faction || point.faction === faction;
      const isType = point.type === type || (!type && point.type === 'city');
      const isClazz = point.class === clazz;
      return isFaction && isType && isClazz;
    })
    .sort(sortName)
    .map(flightPointToLabel);
}

function getOptions(points: FlightPoint[]) {
  return [
    ...['alliance', 'horde', 'both'].map((faction: Faction) => ({
      label: capitalize(faction),
      options: getPoints(points, faction),
    })),
    {
      label: 'Druid',
      options: getPoints(points, 'both', null, 'druid'),
    },
    {
      label: 'Dungeon',
      options: getPoints(points, null, 'dungeon'),
    },
    {
      label: 'Raids',
      options: getPoints(points, null, 'raid'),
    },
  ];
}

interface Props {
  className?: string;
  faction: Faction;
  title: string;
  value: Location;
  points: FlightPoint[];
  onChange: (event) => void;
  isClearable?: boolean;
}

export default function PointSelect(props: Props) {
  const {
    className, faction, title, value, onChange, points, isClearable = false,
  } = props;

  const id = `${title}${faction}`;
  return (
    <StyledPointSelect className={className}>
      <div className="wrapper">
        <Select
          styles={styles}
          aria-labelledby={id}
          value={getPoint(points, value)}
          options={getOptions(points)}
          onChange={onChange}
          isClearable={isClearable}
        />
        <strong id={id} className="label">{title}</strong>
      </div>
    </StyledPointSelect>
  );
}
