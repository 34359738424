import React from 'react';
import Loader from '../../common/components/Loader';
import ErrorStyled from '../../common/components/ErrorStyled';
import Forms from '../components/Forms';
import { useGetPointsQuery } from '../../../types/generated';

interface Props {
  className?: string;
}

export default function FormsContainer(props: Props) {
  const res = useGetPointsQuery();
  const { data, loading, error } = res;

  if (error) {
    return <ErrorStyled>{error.message}</ErrorStyled>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Forms {...props} points={data.getPoints} />
  );
}
