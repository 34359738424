import React from 'react';
import styled from 'styled-components';
import Checkbox from '../../common/components/Checkbox';
import { Location } from '../../../types';
import PointSelect from './PointSelect';
import { getPoints } from '../utils/utils';
import { buttonGroupProps, checkboxProps } from '../../common/utils/inputs';
import FormGroup from './FormGroup';
import ButtonGroup from '../../common/components/ButtonGroup';
import { useField } from '../../common/hooks';
import { Faction, FlightPoint } from '../../../types/generated';

const MAGE_OPTIONS = [
  {
    label: 'X',
    value: null,
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '40',
    value: '40',
  },
  {
    label: '50',
    value: '50',
  },
];

const StyledFormOptions = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  .hearthstone {
    width: 100%;
    grid-column: span 2;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;

    .hearthstone {
      grid-column: span 1;
    }
  }
`;

interface Props {
  className: string;
  faction: Faction;
  points: FlightPoint[];
}

export default function FormOptions(props: Props) {
  const { className, faction, points } = props;
  const druid = useField<boolean>('druid', false, { faction });
  const mage = useField<number>('mage', null, { faction });
  const everlookTeleporter = useField<boolean>('everlookTeleporter', false, { faction });
  const gadgetzanTeleporter = useField<boolean>('gadgetzanTeleporter', false, { faction });
  const [hearth, setHearth] = useField<Location>('hearthstone', '', { faction });

  function handleHearth(e) {
    if (e) {
      setHearth(e.value);
    } else {
      setHearth('');
    }
  }

  const filteredPoints = getPoints(points, faction, true).filter((point) => point.hearthstone);

  return (
    <StyledFormOptions className={className}>
      <FormGroup title="Class">
        <Checkbox name="druid" label="Druid Travel" {...checkboxProps(druid)} />
        <ButtonGroup name="mage" label="Mage Level" values={MAGE_OPTIONS} {...buttonGroupProps(mage)} />
      </FormGroup>
      <FormGroup title="Items">
        <Checkbox name="everlookTeleporter" label="Everlook Teleporter" {...checkboxProps(everlookTeleporter)} />
        <Checkbox name="gadgetzanTeleporter" label="Gadgetzan Teleporter" {...checkboxProps(gadgetzanTeleporter)} />
      </FormGroup>
      <PointSelect
        className="hearthstone"
        title="Hearth"
        faction={faction}
        value={hearth}
        points={filteredPoints}
        onChange={handleHearth}
        isClearable
      />
    </StyledFormOptions>
  );
}
