import React, { useState } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from '../../common/components/Tabbed';
import Form from './Form';
import { getQueryParams } from '../../common/utils/location';
import FormProvider from '../../common/context/form-provider';
import { useLocation, useTheme } from '../../common/hooks';
import { Faction, FlightPoint } from '../../../types/generated';

const FACTIONS: Faction[] = ['alliance', 'horde', 'both'];

interface Props {
  className?: string;
  points: FlightPoint[];
}

export default function Forms(props: Props) {
  const { className, points = [] } = props;
  const { location: { search } } = useLocation();
  const { faction: defaultFaction = 'alliance' } = getQueryParams(search);
  const [faction, setFaction] = useState<Faction>(defaultFaction);

  const defaultIndex = FACTIONS.indexOf(faction);

  useTheme(faction);

  function onSelect(factionIndex) {
    setFaction(FACTIONS[factionIndex]);
  }

  return (
    <Tabs className={className} onSelect={onSelect} defaultIndex={defaultIndex} forceRenderTabPanel>
      <TabList>
        <Tab>Alliance</Tab>
        <Tab>Horde</Tab>
      </TabList>
      <TabPanel>
        <FormProvider>
          <Form
            selectedFaction={faction}
            faction="alliance"
            points={points}
          />
        </FormProvider>
      </TabPanel>
      <TabPanel>
        <FormProvider>
          <Form
            selectedFaction={faction}
            faction="horde"
            points={points}
          />
        </FormProvider>
      </TabPanel>
    </Tabs>
  );
}
