import BarLoader from 'react-spinners/BarLoader';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-align: center;
  width: 100%;
`;

function Loader(props: any) {
  const { theme } = props;
  return (
    <StyledLoader>
      <BarLoader color={theme.primaryLightAlpha} />
    </StyledLoader>
  );
}

export default withTheme(Loader);
