import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { em } from 'polished';

const StyledFormGroup = styled.fieldset`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${em(1)} solid var(--primaryDark-color);

  legend {
    color: var(--primaryLight-color);
    font-size: ${em(16)};
    font-weight: bold;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 10px;
  }

  main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    
    > * {
      flex: 1;
      flex-basis: 130px;
      padding: ${em(4)} 0;
      margin-right: ${em(10)};
      
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`;

interface Props {
  children: ReactNode;
  title: string;
}

export default function FormGroup(props: Props) {
  const { children, title } = props;
  return (
    <StyledFormGroup>
      <legend>{title}</legend>
      <main>
        {children}
      </main>
    </StyledFormGroup>
  );
}
