import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Route from './Route';
import { Location } from '../../../types';
import Loader from '../../common/components/Loader';
import RouteSummary from './RouteSummary';
import { FlightPath } from '../../../types/generated';

const StyledRoutes = styled.div`
  .routes {
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: ${em(20)};
  }

  .expand {
    display: block;
  }

  .hidden {
    display: none;
  }
`;

export interface RouteItem {
  paths: FlightPath[];
}

export interface RoutesProps {
  loading: boolean;
  routes: RouteItem[];
  className?: string;
  onRouteClick: (index: number) => void;
  onPathClick: (path: FlightPath) => void;
  onPointClick: (location: Location) => void;
}

export default function Routes(props: RoutesProps) {
  const {
    className, onPointClick, onPathClick, onRouteClick, routes, loading,
  } = props;

  function handleOnChange(index) {
    onRouteClick(index);
  }

  return (
    <StyledRoutes className={className}>
      <h2>Routes</h2>
      <Accordion className="routes" preExpanded={[0]} onChange={handleOnChange}>
        {
          loading ? <Loader />
            : routes.map(({ paths }, index) => (
              <AccordionItem uuid={index} key={`item${index * 2}`}>
                <AccordionItemHeading className={paths.length === 0 ? 'hidden' : ''}>
                  <AccordionItemButton>
                    <RouteSummary
                      key={`route${index * 2}`}
                      paths={paths}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={routes.length === 1 ? 'expand' : ''}>
                  <Route
                    paths={paths}
                    onPathClick={onPathClick}
                    onPointClick={onPointClick}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            ))
        }
      </Accordion>
    </StyledRoutes>
  );
}
