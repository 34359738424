import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';

const StyledTransportDuration = styled.div`
  padding: ${em(2)};
  font-family: 'Bitter', serif;

  .time {
    font-size: 1.5em;
  }
  
  .suffix {
  font-size: 1.1em;
    margin-left: 2px;
  }
`;

interface Props {
  className?: string;
  duration: number;
}

export default function TransportDuration(props: Props) {
  const { className, duration } = props;

  const minutes = Math.round(duration / 60);
  const seconds = duration % 60;
  const time = minutes === 0 ? seconds : minutes;

  return (
    <StyledTransportDuration className={className}>
      <span className="time">{time}</span>
      <span className="suffix">
        {
          minutes === 0 ? 'sec' : 'min'
        }
      </span>
    </StyledTransportDuration>
  );
}
