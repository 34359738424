import React, { useContext, useEffect, useMemo } from 'react';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { em } from 'polished';
import { Location } from '../../../types';
import PointSelect from './PointSelect';
import { getPoints } from '../utils/utils';
import ButtonIcon from '../../common/components/ButtonIcon';
import FormOptions from './FormOptions';
import { FormContext } from '../../common/context/form-context';
import { useField } from '../../common/hooks';
import { Faction, FlightPoint } from '../../../types/generated';

const FormStyled = styled.form<any>`
  display: grid;
  padding: ${em(20)};
  grid-template-columns: minmax(300px, 450px) max-content 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${em(5)};
  grid-template-areas:
        "fromLocation switch options"
        "toLocation switch options";

  ${(props: any) => (props.isHidden ? 'display: none;' : '')}

  .fromLocation {
    grid-area: fromLocation;
    align-self: end;
  }

  .toLocation {
    grid-area: toLocation;
  }

  .icon {
    grid-area: switch;
    height: 100%;
    cursor: pointer;
    font-size: ${em(25)};
    color: white;
    padding: 0 ${em(5)};
    margin: 0 ${em(5)} 0 auto;

    &:hover {
      color: var(--secondaryLight-color);
    }
  }

  .options {
    grid-area: options;
    border-left: ${em(1)} solid var(--secondaryLight-color);
    padding: 0 0 0 ${em(20)};
  }

  @media (max-width: 1100px) {
      grid-template-columns: 1fr max-content;
      grid-template-areas:
        "fromLocation switch"
        "toLocation switch"
        "options options";

      .icon {
        margin-right: 0;
      }

      .options {
        border-left: 0;
        border-top: ${em(1)} solid var(--secondaryLight-color);
        padding: ${em(10)} 0 0 0;
        margin-top: ${em(10)};
      }
  }
`;

const FACTIONS_DATA: Record<Faction, [Location, Location]> = {
  alliance: ['Stormwind, Elwynn', 'Ratchet, The Barrens'],
  horde: ['Orgrimmar, Durotar', 'Booty Bay, Stranglethorn'],
  both: ['Gadgetzan, Tanaris', 'Light\'s Hope Chapel, Eastern Plaguelands'],
};

interface Props {
  selectedFaction: Faction;
  faction: Faction;
  points: FlightPoint[];
}

export default function Form(props: Props) {
  const { faction, selectedFaction, points } = props;
  const [defaultFrom, defaultTo] = FACTIONS_DATA[faction];
  const { dispatch } = useContext(FormContext);
  const [fromLoc, setFromLoc] = useField<Location>('fromLoc', defaultFrom, { faction });
  const [toLoc, setToLoc] = useField<Location>('toLoc', defaultTo, { faction });

  useEffect(() => {
    if (fromLoc === toLoc && fromLoc !== defaultFrom) {
      setFromLoc(defaultFrom);
    }
    if (fromLoc === toLoc && toLoc !== defaultTo) {
      setToLoc(defaultTo);
    }
  }, []);

  useEffect(() => {
    if (faction === selectedFaction) {
      dispatch({ type: 'update', query: { faction } });
      dispatch({ type: 'trigger' });
    }
  }, [selectedFaction]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  function switchLocations() {
    setToLoc(fromLoc);
    setFromLoc(toLoc);
  }

  function handleFrom(e) {
    setFromLoc(e.value);
  }

  function handleTo(e) {
    setToLoc(e.value);
  }

  const getSelects = useMemo(() => {
    const filteredPoints = getPoints(points, faction, true);
    const fromPoints = filteredPoints.filter((point) => point.location !== toLoc);
    const toPoints = filteredPoints.filter((point) => point.location !== fromLoc);
    return (
      <>
        <PointSelect
          className="fromLocation"
          title="Start"
          faction={faction}
          value={fromLoc}
          points={fromPoints}
          onChange={handleFrom}
        />
        <ButtonIcon onClick={switchLocations} label="settings" className="icon">
          <FontAwesomeIcon icon={faExchangeAlt} />
        </ButtonIcon>
        <PointSelect
          className="toLocation"
          title="End"
          faction={faction}
          value={toLoc}
          points={toPoints}
          onChange={handleTo}
        />
      </>
    );
  }, [fromLoc, toLoc, points]);

  return (
    <FormStyled onSubmit={onSubmit} onChange={onSubmit} isHidden={faction !== selectedFaction}>
      <>{getSelects}</>
      <FormOptions className="options" faction={faction} points={points} />
    </FormStyled>
  );
}
