import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import { Location } from '../../../types';
import TransportIcon from './TransportIcon';
import TransportDuration from './TransportDuration';
import { capitalize } from '../../../shared/utils';
import { FlightPath } from '../../../types/generated';

const StyledRoute = styled.div`
  display: grid;
  grid-gap: ${em(8)};
  background-color: var(--primaryDarkAlpha-color);
  padding: ${em(12)} ${em(16)};
  text-align: left;
`;

const StyledPath = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  justify-content: center;
  cursor: pointer;

  .icon {
    padding: 0;
    position: relative;

    > svg {
      padding: ${em(5)} ${em(0)};
    }

    &:after {
      content: "";
      position: absolute;
      top: ${em(31)};
      bottom: 0;
      left: 8px;
      border-left: ${em(5)} solid var(--secondaryLight-color);
    }
  }

  .point {
    cursor: pointer;
    padding: ${em(4)} 0 ${em(8)};

    &:hover {
      color: var(--secondaryLight-color);
    }
  }

  .location {
    font-weight: bold;
  }

  .duration {
    font-size: 80%;
    margin-top: ${em(2)};
  }

  &:last-of-type {
    .point {
      padding: ${em(4)} 0;
    }
  }
`;

interface Props {
  paths: FlightPath[];
  onPathClick?: (path: FlightPath) => void;
  onPointClick?: (location: Location) => void;
}

export default function Route(props: Props) {
  const {
    onPathClick, onPointClick, paths = [],
  } = props;

  return (
    <StyledRoute>
      {
        paths.length === 0 ? <div>No paths found!</div>
          : paths.map((path) => {
            const [from, to] = path.endpoints;
            const label = path.label || capitalize(path.transport);
            return (
              <StyledPath key={from}>
                <TransportIcon className="icon" path={path} onClick={() => onPathClick(path)} />
                <div className="point" onClick={() => onPointClick(from)}>
                  {label} to <span className="location">{to}</span>
                  <TransportDuration className="duration" duration={path.duration} />
                </div>
              </StyledPath>
            );
          })
      }
    </StyledRoute>
  );
}
