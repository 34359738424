import React from 'react';
import Routes from '../components/Routes';
import { Location } from '../../../types';
import { cleanRoutes } from '../utils/utils';
import ErrorStyled from '../../common/components/ErrorStyled';
import { getQueryParams } from '../../common/utils/location';
import Button from '../../common/components/Button';
import { useLocation } from '../../common/hooks';
import { FlightPath, useGetRoutesQuery } from '../../../types/generated';

interface Props {
  className?: string;
  onRouteClick: (index: number) => void;
  onPathClick: (path: FlightPath) => void;
  onPointClick: (location: Location) => void;
}

export default function RoutesContainer(props: Props) {
  const { location: { search } } = useLocation();
  const res = useGetRoutesQuery({ variables: getQueryParams(search) });
  const {
    data, refetch, loading, error,
  } = res;

  if (error) {
    return (
      <ErrorStyled>
        {error.message}
        <br /><br />
        <Button onClick={() => refetch()}>Retry</Button>
      </ErrorStyled>
    );
  }

  const routes = cleanRoutes(data.getRoutes);
  return (
    <Routes routes={routes} loading={loading} {...props} />
  );
}
