import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { em } from 'polished';

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .label {
    padding-right: ${em(12)};
  }
  
  .buttons {
    display: flex;
    flex-direction: row;
    outline: 1px solid var(--primaryLight-color);
  }
  
  button {
    min-width: 35px;
    background: var(--primaryDark-color);;
    color: inherit;
    border: none;
    padding: ${em(5)} ${em(8)};
    font: inherit;
    font-size: ${em(14)};
    font-weight: bold;
    cursor: pointer;
    outline: inherit;
    
    &:hover {
      filter: brightness(1.2);
    }
  }
  
  .active {
    background: var(--secondaryLight-color);
    color: black;
  }
`;

interface ButtonValue {
  value: string;
  label: string;
  color?: string;
}

interface Props {
  name: string;
  label: string;
  value: string | number;
  values: ButtonValue[];
  onChange: (v) => void;
}

export default function ButtonGroup(props: Props) {
  const {
    label: groupLabel, onChange, value, values,
  } = props;
  const [labelActive, setLabelActive] = useState(values[0].label);

  useEffect(() => {
    if (value) {
      const item = values.find(i => i.value === value.toString());
      setLabelActive(item.label);
    }
  }, [value]);

  function onClick(e) {
    const { value: newValue, label } = e.target.dataset;
    setLabelActive(label);
    onChange(newValue);
  }

  return (
    <StyledButtonGroup>
      <div className="label">{groupLabel}</div>
      <div className="buttons">
        {
          values.map(({ value: v, label }) => (
            <button
              className={labelActive === label ? 'active' : ''}
              type="button"
              key={label}
              onClick={onClick}
              data-value={v}
              data-label={label}
            >
              {label}
            </button>
          ))
        }
      </div>
    </StyledButtonGroup>
  );
}
